import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Paragraph from "../../components/paragraph"
import Grid from "../../components/grid"
import Image from "../../components/image"
import Stack from "../../components/stack"

const Polizeiakte = () => {
  const data = useStaticQuery(
    graphql`
      {
        polizeiakte: file(relativePath: { eq: "og_images/polizeiakte.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 450, height: 660, layout: FIXED)
          }
        }
      }
    `
  )

  return (
        <Stack>
          <Grid columns={[1, 2]} alignY="center">
            <Image
              image={data.polizeiakte.childImageSharp.gatsbyImageData}
              alt="Detaillierte polizeiliche Akte zur Unglücksnacht"
            />
            <Paragraph>
                Eine detaillierte polizeiliche Akte zur Unglücksnacht, die nach rund 150 Jahren heute wieder vorliegt, 
                lässt wenig Zweifel daran, dass ein Brandstifter das Feuer gelegt haben muss.<br />
                Trotz bestehender Verdächtigungen erhob die Staatsanwaltschaft damals keine Anklage. 
                Heute können wir mit der Polizeiakte und den darin enthaltenen Befragungen von Zeugen 
                und Verdächtigen die Brandnacht rekonstruieren.<br />
                Rollen wir den Fall wieder auf!
            </Paragraph>
          </Grid>
        </Stack>
  )
}

export default Polizeiakte