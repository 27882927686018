import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../../components/container"
import Stack from "../../components/stack"
import QuotePrimary from "../../components/quote-primary"
import Title from "../../components/title"
import Intro from "../../components/intro"
import Paragraph from "../../components/paragraph"
import Image from "../../components/image"
import Constrain from "../../components/constrain"
import Grid from "../../components/grid"
import Box from "../../components/box"
import Bullet from "../../components/bullet"
import BulletList from "../../components/bullet-list"
import Heading from "../../components/heading"
import Text from "../../components/text"
import AudioPlayer from "../../components/audio-player"

const Tathergang = () => {
  const data = useStaticQuery(graphql`
    query {
        beigelegterBrief: file(relativePath: { eq: "og_images/beig-brief.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 500, height: 651, layout: FIXED)
          }
        }
    }
  `)
  return (
    <Stack>
      <Title top="Tathergang" middle="" bottom="" />
      <Box bg="muted" p={[6, 12]} height="100%">
            <Stack space={6}>
              <Heading as="h3" level={4}>
                Aus den Zeugenaussagen ergibt sich ein klares Bild: 
              </Heading>
              <BulletList size={3} space={6} color="primary">
                <Bullet icon="bulb">
                    Bereits zwei bis drei Wochen vor der Tat hat der Täter sich zum ersten Mal Zugang zum Haus verschafft, 
                    wurde aber vom Haushund vertrieben. 
                </Bullet>
                <Bullet icon="bulb">
                    Vier bis sechs Tage vor dem Feuer hat er einen Brennholzstapel, der im Gang auf der Kellerdecke neben der Treppe hoch zum 1. OG aufgetürmt war, 
                    manipuliert, genauer: mit Petroleum übergossen, und Reisig und einen Wäschestangen aus Holz dazugelegt. 
                    Er musste nun nur noch einen geeigneten Moment abpassen. 
                </Bullet>
                <Bullet icon="bulb">
                    Die Haustür der Eberhardts wurde in der Regel nicht abgesperrt, der Hund wachte ja darüber. 
                    Abends wurde er für gewöhnlich hinausgelassen, und kam selbständig wieder heim. 
                    Am Abend der Tat kam der Hund jedoch nicht wieder zurück; der Täter passte ihn wohl ab. 
                    So konnte er den Holzstapel ungestört in Brand zu setzen.
                </Bullet>
                <Bullet icon="bulb">
                    Wenige Minuten später standen der Gang und die Treppe in Flammen, kurz darauf brannte das gesamte Obergeschoss.
                </Bullet>
              </BulletList>
            </Stack>
        </Box>
        <Stack space={6}>
            <Intro>
                Vernehmungsprotokolle
            </Intro>
            <AudioPlayer
                src={`sonderausstellungen/feuerteufel/c-f-eberhardt`}
                title={
                    <Text as="span" sans>
                        Carl Friedrich Eberhardt{" "}
                        <Text bold as="strong"></Text>
                    </Text>
                }
            />
        </Stack>
    </Stack>
  )
}

export default Tathergang