import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../../components/container"
import Stack from "../../components/stack"
import QuotePrimary from "../../components/quote-primary"
import Title from "../../components/title"
import Intro from "../../components/intro"
import Paragraph from "../../components/paragraph"
import Image from "../../components/image"
import Constrain from "../../components/constrain"
import Grid from "../../components/grid"

const Ermittlungsstand = () => {
  const data = useStaticQuery(graphql`
    query {
        beigelegterBrief: file(relativePath: { eq: "og_images/beig-brief.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 460, height: 660, layout: FIXED)
          }
        }
        akteStaatsanwalt: file(relativePath: { eq: "og_images/akte-staa.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 460, height: 300, layout: FIXED)
          }
        }
    }
  `)
  return (
    <Stack>
      <Title top="Ermittlungsstand" middle="" bottom="" />
      <Intro>
        Aus der Feuerpolizeiakte: Brief von Oberamtmann Sandberger an die Tübinger Staatsanwaltschaft:
      </Intro>
      <Grid columns={[1, 2]} alignY="center">
            <Image
              image={data.beigelegterBrief.childImageSharp.gatsbyImageData}
              alt="Foto des Briefes von Oberamtmann Sandberger an die Tübinger Staatsanwaltschaft"
            />
            <Paragraph>
                Für den leitenden Ermittler, Oberamtmann Sandberger, war der Tatbestand der Brandstiftung durch die Aussagen 
                der vernommenen Zeugen faktisch erwiesen. Er leitete die Akte mit seinem Bericht zum Brandhergang 
                und den Protokollen der Zeugenbefragungen kurz vor Weihnachten an die Staatsanwaltschaft weiter. 
                Im beigelegten Brief unterrichtet er diese von seinem schlimmen Verdacht:
            </Paragraph>
      </Grid>
      <QuotePrimary author="">
        <span>
          Während kaum eine halbe Stunde vor Ausbruch des Feuers die Studierenden Faber und Gußmann die Hausflur 
          und Stiege im Nachhausegehen passirten, ohne irgend etwas Verdächtiges, weder Feuer, 
          noch Rauch noch Brandgeruch wahrzunehmen, loderte kaum ½ Stunde nach ihrer Heimkunft das Feuer 
          mit solcher Gewalt auf und verbreitete sich so rapid, daß bei solchem Sachverhalt, der Verdacht, 
          daß hier ein Feuer absichtlich gelegt und genährt wurde, sich aufdrängt. 
          Es ist daher in objektiver Beziehung der Verdacht der Brandstiftung angeregt, 
          wogegen freilich die polizeiliche Untersuchung über die Urheberschaft keine Anhaltspunkte gewonnen hat.
        </span>
      </QuotePrimary>
      <Grid columns={[1, 2]} alignY="center">
            <Paragraph>
                Jedoch wurde die Akte vom Staatsanwalt bereits drei Tage später wieder zurückgesandt - mit der Randnotiz, 
                dass er »zur Zeit noch keinen Grund z. Erhebung einer gerichtlichen Klage wegen Brandstiftung finden konnte.« 
            </Paragraph>
            <Image
              image={data.akteStaatsanwalt.childImageSharp.gatsbyImageData}
              alt="Foto vom Rückschreiben des Staatsanwalts an Oberamtmann Sandberger"
            />
      </Grid>
      <Intro>
        Damit wurde die Ermittlung beendet und die Akte nach Auszahlung der Versicherungssummen geschlossen. Bis heute!
      </Intro>
    </Stack>
  )
}

export default Ermittlungsstand