import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../../components/container"
import Stack from "../../components/stack"
import QuotePrimary from "../../components/quote-primary"
import Title from "../../components/title"
import Intro from "../../components/intro"
import Paragraph from "../../components/paragraph"
import Image from "../../components/image"
import Constrain from "../../components/constrain"

const Schadensbild = () => {
  const data = useStaticQuery(graphql`
    query {
      schadensbildEins: file(
        relativePath: { eq: "og_images/schadensbild-1.jpg" }
      ) {
        ...largeImage
      }
      schadensbildZwei: file(
        relativePath: { eq: "og_images/schadensbild-2.jpg" }
      ) {
        ...largeImage
      }
      schadensbildDrei: file(
        relativePath: { eq: "og_images/schadensbild-3.jpg" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Stack>
      <Title top="Schadensbild" middle="" bottom="" />
      <QuotePrimary author="Karl Gußmann, der letzte Bewohner von Hölderlins Turmzimmer, 1927">
        <span>
          Wo ist er hingekommen, der alte Hölderlinsturm, der sich als Erkerturm so vorzüglich in Zwinger und Stadtmauer angepasst hatte?! […] 
          Der Turm wurde geschmacklos als Rundturm wieder aufgebaut und ein mit Gerümpel angefülltes sog. Hölderlinszimmer später als Sehenswürdigkeit verzapft. […] 
          Von dem, was heute steht, da man in Tübingen den † Herrn Bibliothekarius als wertvolles Tübinger Fossil andächtig herumzureichen pflegte, 
          ist echt lediglich der umbaute Luftraum dort über dem geduldigen, schläfrigem Neckar.
        </span>
      </QuotePrimary>
      <Image
        image={data.schadensbildEins.childImageSharp.gatsbyImageData}
        alt="Schwarzweiß-Fotografie des Hölderlinturm"
        caption="So kannte Hölderlin das Haus. Sein Pflegevater Ernst Zimmer stockte 1820 den „turmartigen Vorbau“ auf 
                und baute später das Erdgeschoss als Wohnraum aus. (Foto: Paul Sinner, vor 1874)"
      />
      <Image
        image={data.schadensbildZwei.childImageSharp.gatsbyImageData}
        alt="Schwarzweiß-Fotografie vom Hölderlinturm, wie Hölderlin selbst ihn kannte"
        caption="Vor dem Brand, 1874. Hier sieht man den Ausbau des Dachgeschosses, 
                der 1874 entgegen Widerstände des Nachbarn und Brauereibesitzers Betz von Carl Friedrich Eberhardt vorgenommen wurde. 
                Wegen des beengten Raumes im Zwinger, den sich die beiden teilen mussten, 
                lieferten sie sich einen erbitterten Nachbarschaftsstreit"
      />
      <Image
        image={data.schadensbildDrei.childImageSharp.gatsbyImageData}
        alt="Fotografie Hölderlinturm: Der noch unverputzte Hölderlinturm nach dem Brand, 1876"
        caption="Der noch unverputzte Hölderlinturm nach dem Brand, 1876. Stadtarchiv Tübingen"
      />
      <Stack space={6}>
      </Stack>
    </Stack>
  )
}

export default Schadensbild