import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Anchor from "../../components/anchor"
import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import SectionSpacing from "../../components/section-spacing"
import Stack from "../../components/stack"
import TableOfContents from "../../components/table-of-contents"
import VideoPlayer from "../../components/video-player"

import Polizeiakte from "../../content/feuerteufel/polizeiakte"
import Schadensbild from "../../content/feuerteufel/schadensbild"
import Ermittlungsstand from "../../content/feuerteufel/ermittlungsstand"
import Tathergang from "../../content/feuerteufel/tathergang"
import Verdaechtigungen from "../../content/feuerteufel/verdaechtigungen"
import Impressum from "../../content/feuerteufel/impressum"


const FeuerteufelPage = () => {
  const data = useStaticQuery(graphql`
    query FeuerteufelQuery {
      postkarte: file(
        relativePath: { eq: "og_images/feuer-postkarte.jpg" }
      ) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 675, layout: FIXED)
          }
      }
      thumbnail: file(
        relativePath: { eq: "hegel-hoelderlin/feuerteufel-thumbnail.jpg" }
      ) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 675, layout: FIXED)
          }
      }
    }
  `)

  return (
    <Layout backdrop="orte">
      <Seo
        title="Turm in Flammen!"
        image={data.postkarte.childImageSharp.gatsbyImageData}
      />
      <TableOfContents
        items={[
          {
            anchor: "polizeiakte",
            title: "Polizeiakte",
          },
          {
            anchor: "schadensbild",
            title: "Schadensbild",
          },
          {
            anchor: "ermittlungsstand",
            title: "Ermittlungsstand",
          },
          {
            anchor: "tathergang",
            title: "Tathergang",
          },
          {
            anchor: "verdächtigungen",
            title: "Verdächtigungen",
          },
        ]}
      />
      <Stack>
        <Stack>
          <Stack>
            <Breadcrumb
              items={[
                {
                  title: "Digital",
                  link: "/digital",
                },
                {
                  title: "Sonderausstellungen",
                  link: "/sonderausstellungen",
                },
                {
                  title: "Turm in Flammen!",
                  link: "/sonderausstellungen/turm-in-flammen",
                },
              ]}
            />
            <PageTitle>Turm in Flammen!</PageTitle>
            <Stack space={3}>
              <Heading as="div" level={3}>
                Die Fahndungsakte „Feuerteufel“<br />
                23. September 2024 bis 19. Januar 2025. <strong>Verlängert bis 28. April!</strong>
              </Heading>
              <VideoPlayer
                src="feuerteufel/feuerteufel-trailer"
                poster={data.thumbnail.childImageSharp.gatsbyImageData}
              />
            </Stack>
          </Stack>
          <Paragraph dropcap={true}>
            Es brennt! In der Nacht zum 14. Dezember 1875 steht die »Eberhardtei« lichterloh in Flammen. 
            Viele kennen das Haus am Neckar wegen seiner beliebten Warm-Badeanstalt. Und weitere Unglücke folgen: 
            Die Stadt wird danach von einer ganzen Serie verheerender Brände heimgesucht. 
            Das Gerücht vom Tübinger Feuerteufel geht um. Der Hund des Badbetreibers Eberhardt bleibt vermisst 
            und der Schriftsteller Bruno Wille erinnert an Mörikes Ballade vom Feuerreiter.
            <br /><br />
            Was hat dies alles mit Hölderlin zu tun? Und vor allem: Wer ist für die mutmaßliche Brandstiftung verantwortlich? 
          </Paragraph>
        </Stack>
        <SectionSpacing>
          <Anchor id="polizeiakte">
            <Polizeiakte />
          </Anchor>
          <Anchor id="schadensbild">
            <Schadensbild />
          </Anchor>
          <Anchor id="ermittlungsstand">
            <Ermittlungsstand />
          </Anchor>
          <Anchor id="tathergang">
            <Tathergang />
          </Anchor>
          <Anchor id="verdaechtigungen">
            <Verdaechtigungen />
          </Anchor>
          <Impressum />
        </SectionSpacing>
      </Stack>
    </Layout>
  )
}

export default FeuerteufelPage